/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    z-index: 3; /* Increased z-index to keep it above other elements */
    padding: 0.75rem 1.5rem;
}

/* Logo styling */
.logo img {
    height: 4.5rem;
    cursor: pointer;
}

/* Navigation links */
.navLinks {
    list-style: none;
    display: flex;
    gap: 1.25rem;
}

.navLinks li {
    display: inline;
}

.navLinks a {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.navLinks a:hover {
    color: #ffcc00;
}

/* Mobile menu button */
.menuButton {
    display: none;
    background: none;
    border: none;
    font-size: 2rem; /* Made it bigger */
    color: white;
    cursor: pointer;
    z-index: 4; /* Ensures the button is above everything */
}

/* Full-screen mobile menu */
@media (max-width: 768px) {
    .navLinks {
        display: none;
        flex-direction: column;
        background-color: rgba(
            0,
            0,
            0,
            0.9
        ); /* Darker background for better visibility */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh; /* Full-screen menu */
        justify-content: center;
        align-items: center;
        z-index: 3;
    }

    .navLinks.show {
        display: flex;
    }

    .navLinks a {
        font-size: 2rem; /* Increased size for easier clicking */
        padding: 1rem;
    }

    .menuButton {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1.5rem;
    }
}
