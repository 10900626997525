/* Reset Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

/* Global Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    font-size: 3rem;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 1.75rem;
}
h5 {
    font-size: 1.5rem;
}
h6 {
    font-size: 1.25rem;
}

p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

ul,
ol {
    padding-left: 20px;
}

li {
    margin-bottom: 8px;
}

/* Public Button Styles */
button {
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

button:hover {
    opacity: 0.9;
}

/* Responsive Typography */
@media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    p {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.75rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    p {
        font-size: 0.9rem;
    }
}
