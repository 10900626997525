.landingContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/landingImg.webp');
    background-size: cover;
    background-position: center;
    filter: brightness(75%);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.contentBox {
    position: relative;
    color: white;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-shadow: 0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.5);
}

.subtitle {
    margin-top: 1rem;
    max-width: 37.5rem;
    text-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.5);
    color: antiquewhite;
    font-size: 1.25rem;
}

.button {
    margin-top: 1.5rem;
    background-color: #7acdf0;
    padding: 0.625rem 1.25rem;
    font-size: 1.125rem;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 0.25rem;
}

.button:hover {
    background-color: #5cbce4;
}
